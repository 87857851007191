(function() {

    const ADD_DELETE_ITEM_URL = "/shop/add_to_cart";
    const ITEM_INC_DEC_URL = "/update_item_count";

    const sendAddRemoveRequest = (data, url, method) => {
        $result = false;
        $.ajax({
            type: method,
            url: url,
            data: data,
            async: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            success: function(result) {
                if( result.code == 200 ) $result = true;
            }
        });

        console.log($result);
        return $result;
    };

    const updateTotalPrice = () => {
        const itemCosts = $('.item-total-price');
        let totalCost = 0.0;
        $.each(itemCosts, function(i, el) {
           let cost = $(el).text().replace('INR ', '');
           totalCost += parseFloat(cost);
        });

        $('#cart-total').text(totalCost.toFixed(2));

    };

    // remove item button
    if( $('[data-action="remove-item"]').length ) {
        $('[data-action="remove-item"]').on('click', function(){
            const itemElement = $(this).closest('.cart-item');
            if(sendAddRemoveRequest({'item_id': itemElement.attr('id')}, ADD_DELETE_ITEM_URL, 'DELETE')) {
                itemElement.remove();
                updateTotalPrice();
            }
        });
    }

    // decrease an item
    if( $('.inc-dec-action').length ) {
        $('.inc-dec-action').on('click', function() {
            const itemElement = $(this).closest('.cart-item');
            const itemCountElement = $(this).siblings('.item-count');
            const item_unit_price = parseFloat(itemElement.children('.item-unit-price').text().replace('INR ', ''));
            const itemTotalElement = itemElement.children('.item-total-price');
            const item_count = parseInt(itemCountElement.val());
            const action = $(this).data('action');

            let item_total_price = 0;

            if( action == "inc" ) {
                if( sendAddRemoveRequest({'item_id': itemElement.attr('id'), 'action': action}, ITEM_INC_DEC_URL, 'POST') ) {
                    itemCountElement.val( item_count + 1 );
                    item_total_price = item_unit_price * (item_count + 1);
                    itemTotalElement.text(`${item_total_price}`);
                }
            }

            if( action == "dec" ) {
                if( item_count > 1 ) {
                    if( sendAddRemoveRequest({'item_id': itemElement.attr('id'), 'action': action}, ITEM_INC_DEC_URL, 'POST') ) {
                        itemCountElement.val( item_count - 1 );
                        item_total_price = item_unit_price * (item_count - 1);
                        itemTotalElement.text(`${item_total_price}`);
                    }
                } else {
                    if(sendAddRemoveRequest({'item_id': itemElement.attr('id')}, ADD_DELETE_ITEM_URL, 'DELETE') ) {
                        itemElement.remove();
                    }
                }
            }

            updateTotalPrice();
        });
    }

    $('#buy-items').on('click', function() {
        console.log('buying...');
        let billing_address = $('[name="shipping_address"]:checked').val();
        if( billing_address ) {
            $('#shipping-details-form').submit();
        } else {
            Swal.fire('Shipping address not found. Please <a href="/account" class="simple-link">add</a> and then continue.');
        }
        
    });

    $('#magz-download-btn').on('click', function() {
        event.preventDefault();
        if( $(this).hasClass('btn-disabled') ) {
            Swal.fire('Please <a href="/plans" class="simple-link">Choose a Plan</a> and then continue.');
        }
    })

})();
