$(document).ready(function() {
    $('#slide-menu-button').on('click', function(){
        $('#slide-menu').toggleClass('show');
        if($('#slide-menu-button .fas').hasClass('fa-bars')) {
            $('#slide-menu-button .fas').removeClass('fa-bars')
            $('#slide-menu-button .fas').addClass('fa-times')
        } else {
            $('#slide-menu-button .fas').removeClass('fa-times')
            $('#slide-menu-button .fas').addClass('fa-bars')
        }
    });

    if( $('.tinymceeditor').length ) {
        tinymce.init({
            selector: '.tinymceeditor',
            plugins: [
                'advlist autolink lists link image charmap print preview anchor image',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
            toolbar_mode: 'floating',
        });
    }

    if( $('.tinymceeditor-simple').length ) {
        tinymce.init({
            selector: '.tinymceeditor-simple',
            menubar: false,
            plugins: [
                'advlist autolink lists link charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
            toolbar_mode: 'floating',
        });
    }

    const addTag = (tagValue, target) => {
        if(tagValue && target) {
            const value = tagValue.toLowerCase().trim();
            let target_input = target + '_input';
            let existing_tags = $(target_input).val();
            if(existing_tags.length) {
                let existing_tags_arr = existing_tags.split(',');
                if( existing_tags_arr.indexOf(value) === -1 )
                    $(target_input).val(`${existing_tags}, ${value}`);
            } else
                $(target_input).val(`${value}`);
            let tagEl = `<span class="article-tag badge bg-primary">${value} <i class="far fa-times-circle"></i></span>`;
            $(target).append(tagEl);
        } else {
            console.error('Value error');
        }
    };

    const removeTag = (tagValue, target) => {
        if( tagValue && target ) {
            const value = tagValue.toLowerCase().trim();
            let target_input = '#' + target + '_input';
            const existing_values = $(target_input).val();
            if( existing_values.length ) {
                const existing_values_arr = existing_values.replace(', ', ',').split(',');
                if( existing_values_arr.indexOf(value) !== -1 ) {
                    let elIndex = existing_values_arr.indexOf(value);
                    existing_values_arr.splice(elIndex, 1);
                    $(target_input).val(existing_values_arr.join());
                }
            }
        } else {
            console.error('Value error');
        }
    };

    if( $('.tag-add').length ) {
        $('.tag-add').on('keypress', function(event){
            if( event.keyCode == 13 ) {
                event.preventDefault();
                const tag_value = $(this).val();
                let target = $(this).data('target');
                addTag(tag_value, target);
                $(this).val('');
            }
        });
    }

    if( $('.article-tag-container').length ) {
        $('.article-tag-container').on('click', '.article-tag', function() {
            const tag_value = $(this).text();
            const tag_parent_id = $(this).parent('.article-tag-container').attr('id');
            removeTag(tag_value, tag_parent_id);
            $(this).remove();
        });
    }

    if( $('.has-char-counter').length ) {
        $('.has-char-counter').on('keyup', function() {
            const _id = $(this).attr('id');
            let val = $(this).val();
            $('#'+_id+'-character-count span').text(val.length);
        });
    }



    // load more
    $('#load-more-form').on('submit', function(){
        event.preventDefault();
        let url = $(this).attr('action');
        console.log($(this).serialize());
        // $.get(url, )
        return false;
    });



});



