$(document).ready(function() {
    const ADD_DELETE_ITEM_URL = "/shop/add_to_cart";

    if( $('.add-to-cart-link').length ) {
        $('.add-to-cart-link').on('click', function(event) {
            event.preventDefault();
            const el = $(this);
            const itemID = el.data('itemid');
            console.log(itemID);
            let method = "POST";
            console.log($(this));
            console.log(el.attr('class'));
            if( el.hasClass('remove') )
                method = "DELETE";

            console.log(method);
            $.ajax({
                type: method,
                url: ADD_DELETE_ITEM_URL,
                data: {
                    'item_id': itemID,
                },
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
                success: function(result) {
                    if( result.code == 200 ) {
                        if( el.hasClass('remove') ) {
                            el.text('Add to Cart');
                            el.removeClass('remove');
                        } else {
                            el.text('Remove');
                            el.addClass('remove');
                        }
                    }
                }
            });

        });
    }

    // banner carousel
    if( $('.shop-carousel.carousel-single').length ) {
        $('.shop-carousel.carousel-single').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            dots: false,
            items: 1,
        });
    }

    if( $('.shop-carousel').length ) {
        $('.shop-carousel').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            dots: false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:6
                }
            }
        });
    }

    /**
     * Shop filter
     */
    const filter_shop_category_tags = ['all'];
    $('.category-filter-tag').on('change', function() {
        let el_id = $(this).attr('id');
        let el = $('#'+el_id);
        console.log(el.is(':checked'));
        let el_tag = el.data('tag');
        let tagIndex = filter_shop_category_tags.indexOf(el_tag);
        let tagIndexAll = filter_shop_category_tags.indexOf('all');
        if(el.is(':checked')) {
            console.log(el_tag);
            if(el_tag.length && tagIndex === -1 )
                filter_shop_category_tags.push(el_tag);
        } else {
            if( filter_shop_category_tags.indexOf(el_tag) !== -1 ) {
                filter_shop_category_tags.splice(tagIndex, 1);
            }
        }

        filter_shop_items();
    });

    const filter_shop_items = () => {
        const shop_items = $('.shop-books-and-magazines .shop-item');
        console.log(filter_shop_category_tags);
        let tagIndexAll = filter_shop_category_tags.indexOf('all');
        if( tagIndexAll !== -1 ) {
            $.each(shop_items, function(index, el) {
                let el_data_category = $(el).data('category');
                $(el).attr('style', '');
            });
        } else {
            $.each(shop_items, function(index, el) {
                let el_data_category = $(el).data('category');
                if( filter_shop_category_tags.indexOf(el_data_category) === -1 ) {
                    $(el).attr('style', 'display: none !important;');
                } else {
                    $(el).attr('style', '');
                }
            });
        }

    };
});
